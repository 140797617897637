import React from 'react'
import styled from '@emotion/styled'
import IconExternal from '../../../static/images/external_link.svg'

// Sections
const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
	margin-bottom: ${props => props.marginBottom ? props => props.marginBottom : 0}rem;
  padding: ${props => props.padding}rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 1 : 0}rem;
    padding: ${props => props.padding > 1 ? props => props.padding - 1 : props => props.padding}rem 0;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 2 : 0}rem;
    padding: ${props => props.padding > 2 ? props => props.padding - 2 : props => props.padding}rem 0;
		background: ${props => props.background ? `rgba(174,183,191,0.1)` : 'initial'};
  }
`
const SectionContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
	max-width: 60rem;
  width: 100%;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
const SectionContentTitle = styled.h2`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 2em;
	line-height: 3rem;
	padding: 1rem 0;
	margin: 0 2rem;
	text-align: center;
`
const SectionContentRow = styled.div`
	display: flex;
	flex: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 20rem;
	padding-top: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`

// Table
const ServiceScheduleTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
	}
`
const ServiceScheduleHeader = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background: rgba(67,182,73,0.1);
`
const ServiceScheduleRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: 1px solid #AEB7BF;
`
const ServiceScheduleCell = styled.div`
	display: flex;
	flex: ${props => props.flex};
	width: 100%;
	min-height: ${props => props.minHeight ? props.minHeight : `5`}rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	word-break: keep-all;
	font-size: 1.2em;
	font-weight: ${props => props.fontWeight ? props.fontWeight : `initial`};
	font-family: "Apple SD Gothic Neo";
	padding: 1rem 0;
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	line-height: 1.6rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 0.9em;
  }
`
const MobileLineBreak = styled.br`
	&:after {
  }
	display: none;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`
const MapLink = styled.a`
	font-family: "Apple SD Gothic Neo";
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 0.9em;
  }
`

const CalendarPageRecurring = () => {
	return (
		<>
			<SectionContainer padding={1}>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionContentTitle>{`예배 시간 & 장소`}</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<ServiceScheduleTable>
							<ServiceScheduleHeader>
								<ServiceScheduleCell flex={2} fontWeight={700} minHeight={3.5}>{`예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`시간`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`장소`}</ServiceScheduleCell>
							</ServiceScheduleHeader>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`주일예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 1:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus)`}
										<IconExternal style={{ marginLeft: '0.5rem' }} />
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`새벽예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`화-금 5:30AM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus) - Roots Room`}
										<IconExternal style={{ marginLeft: '0.5rem' }} />
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`금요예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`매달 마지막 금 7:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus)`}
										<IconExternal style={{ marginLeft: '0.5rem' }} />
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
						</ServiceScheduleTable>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
			<SectionContainer padding={1}>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionContentTitle>{`교육사역 시간 & 장소`}</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<ServiceScheduleTable>
							<ServiceScheduleHeader>
								<ServiceScheduleCell flex={2} fontWeight={700} minHeight={3.5}>{`교육사역 명`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`시간`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`장소`}</ServiceScheduleCell>
							</ServiceScheduleHeader>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`유아/유치부 예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 1:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 107`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`초등부 예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 1:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 106`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`중고등부 예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 1:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 109`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`소망부 예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 1:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 112`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`카리스마 대학부 모임`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`학기중 수 7:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/ChuAVohTKH9mZQAh6" target="_blank">
										{`First Presbyterian Church of Berkeley`}
										<IconExternal style={{ marginLeft: '0.5rem' }} />
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`카이로스 청년부 모임`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`금요일 8 PM &`}<br />{`주일예배 후 목장 모임`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`본당`}</ServiceScheduleCell>
							</ServiceScheduleRow>
						</ServiceScheduleTable>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
			<SectionContainer padding={1} marginBottom={4}>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionContentTitle>{`주기적 모임 시간 & 장소`}</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<ServiceScheduleTable>
							<ServiceScheduleHeader>
								<ServiceScheduleCell flex={2} fontWeight={700} minHeight={3.5}>{`주기적 모임 명`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`시간`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`장소`}</ServiceScheduleCell>
							</ServiceScheduleHeader>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`커피브레이크`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`담당자 문의: coffeebreak@joyfulchurch.us`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`여성팀: 화수금 10am`}<br />{`수목 8pm 남성팀: 화목 8pm`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`여성 큐티방`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`목 10:00AM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 102`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`남성 큐티방`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`토 7:00AM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`Room 108`}</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`"성경 통독" 성경 공부`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`담당자 문의: heayoung.cho@joyfulchurch.us`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500}>{`(Zoom으로 모입니다.)`}</ServiceScheduleCell>
							</ServiceScheduleRow>
						</ServiceScheduleTable>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
		</>
	)
}

export default CalendarPageRecurring
