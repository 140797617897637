import React from 'react'
import Layout from '../../components/Layout'
import ContainerFullWidth from '../../components/ContainerFullWidth'
import PageTitle from '../../components/PageTitle'
import BodyPlaceholder from '../../components/BodyPlaceholder'
import SEO from '../../components/SEO'
import CalendarPage from '../../components/Info/CalendarPage'

const Calendar = ({ location }) => {
  return (
    <Layout>
      <SEO title="교회 일정 | 안내 - 죠이플 교회" description="Joyful Church events and schedule" />
      <ContainerFullWidth background={'background'}>
        <CalendarPage hash={location.hash} />
      </ContainerFullWidth>
    </Layout>
  )
}

export default Calendar
