import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import IconExternal from '../../../static/images/external_link.svg'
import IconCheck from '../../../static/images/check.svg'

// Sections
const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
	margin-bottom: ${props => props.marginBottom ? props => props.marginBottom : 0}rem;
  padding: ${props => props.padding}rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 1 : 0}rem;
    padding: ${props => props.padding > 1 ? props => props.padding - 1 : props => props.padding}rem 0;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 2 : 0}rem;
    padding: ${props => props.padding > 2 ? props => props.padding - 2 : props => props.padding}rem 0;
		background: ${props => props.background ? `rgba(174,183,191,0.1)` : 'initial'};
  }
`
const SectionContentContainer = styled.div`
	display: ${props => props.hidden ? `none` : `flex`};
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
	max-width: 60rem;
  width: 100%;
  padding-top: 0.25rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
const SectionContentRow = styled.div`
	display: flex;
	flex: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 20rem;
	padding-top: 0.25rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
// Section Menu
const SectionMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
    padding: 0.5rem 0;
	}
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 1rem 0.5rem;
  }
`
const SectionMenuItem = styled.div`
  display: flex;
  max-width: 20rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const SectionMenuItemText = styled.span`
  display: flex;
  font-size: 1em;
  font-family: "Apple SD Gothic Neo";
  font-weight: 400;
  line-height: 1.4rem;
  padding-top: 3px;
  margin: 0 0.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 0.9em
  }
`
const IconCheckBox = styled.div`
  border: 1px solid ${props => props.theme.colors.green};
  width: 22px;
  height: 22px;
  padding: 3px 0 0 1px;
  cursor: pointer;
`
const StyledIconCheck = styled(IconCheck)`
  display: ${props => props.selected == true ? `initial` : `none`};
`
const StyledSelect = styled.select`  
  text-align: left;
  padding:  0.5rem;
  width: 8rem;
  color: ${props => props.theme.colors.text};
  border: 1px solid ${props => props.theme.colors.green};
  border-radius: 0;
  background-color: white;
  background-image:
    linear-gradient(45deg, transparent 50%, ${props => props.theme.colors.green} 50%),
    linear-gradient(135deg, ${props => props.theme.colors.green} 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  font: inherit;
  -webkit-appearance: none;
  &:focus{
    outline:0;
  }
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    width: 7.5rem;
    font-size: 0.9em
  }
`
// Events List
const EventContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 1rem 0;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
    flex-direction: column;
	}
`
const EventDetailsContainer = styled.div`
// border: 1px solid rgba(47, 49, 51, 0.15);
  display: flex;
  flex: 100%;
  flex-direction: column;
  height: ${props => props.height ? props => props.height + `rem` : `auto`};
  min-height: 15rem;
  padding-left: 2rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 0 1rem;
  }
`
const EventDetailsDate = styled.div`
  // border: 1px solid rgba(47, 49, 51, 0.15);
  word-break: keep-all;
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  font-size: 1.2em;
  padding-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${props => props.theme.colors.green};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1em;
  }
`
const EventDetailsName = styled.div`
  // border: 1px solid rgba(47, 49, 51, 0.15);
  display: flex;
  width: 100%;
  height: fit-content;
  padding-top: 0.25rem;
  align-items: center;
  font-family: "Apple SD Gothic Neo";
  font-size: 1.6em;
  font-weight: 700;
  line-height: 2rem;
  color: ${props => props.theme.colors.text};
`
const EventDetailsTagContainer = styled.div`
  // border: 1px solid rgba(47, 49, 51, 0.15);
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 0.25rem 0;
`
const EventDetailsTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Apple SD Gothic Neo";
  font-size: 0.85em;
  line-height: 1.2rem;
  vertical-align: middle;
  height: fit-content;
  padding: 3px 8px 1px 8px;
  margin-right: 0.5rem;
  border: 1px solid ${props => props.theme.colors.green};
  border-radius: 1rem;
`
const EventDetailsItemContainer = styled.div`
  // border: 1px solid rgba(47, 49, 51, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0.5rem 0;
`
const EventDetailsItem = styled.div`
  word-break: keep-all;
  display: inline;
  align-items: center;
  width: 100%;
  height: fit-content;
  font-family: "Apple SD Gothic Neo";
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4rem;
  padding: 0.05rem 0;
`
const EventDetailsDesc = styled.div`
  word-break: keep-all;
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  font-family: "Apple SD Gothic Neo";
  font-size: 1em;
  line-height: 1.4rem;
  padding: 0.05rem 0;
`
const EventDetailsCTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 0.5rem 0;
`
const EventDetailsCTALink = styled.a`
  display: flex;
  align-items: center;
  height: fit-content;
  font-family: "Apple SD Gothic Neo";
  font-size: 1em;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.4rem;
  padding: 0.05rem 0;
  margin-right: 1rem;
  color: ${props => props.theme.colors.orange};
`
const TagLink = styled.a`
	font-family: "Apple SD Gothic Neo";
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
`
const StyledImg = styled(Img)`
  display: flex;
  flex: 100%;
  max-width: ${props => props.width}em;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    visibility: hidden;
		max-width: initial;
    margin: 0 0.5rem;
  }
`
const EventImage = ({ filename, width }) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					vbs2022: file(relativePath: { eq: "vbs2022.jpg" }) {
						childImageSharp {
							fixed(width: 300, height: 200, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
					morning: file(relativePath: { eq: "morning.png" }) {
						childImageSharp {
							fixed(width: 300, height: 200, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
					retreat: file(relativePath: { eq: "retreat.png" }) {
						childImageSharp {
							fixed(width: 300, height: 200, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
					coffee: file(relativePath: { eq: "coffee.png" }) {
						childImageSharp {
							fixed(width: 300, height: 200, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
					dawn: file(relativePath: { eq: "dawn.png" }) {
						childImageSharp {
							fixed(width: 300, height: 200, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			`}
			render={data => (
				<StyledImg
					className="InfoImage"
					fixed={data[`${filename}`].childImageSharp.fixed}
					width={width}
          layout="constrained"
				/>
			)}
		/>
	)
}

const CalendarPageEvents = () => {
  const [selected, setSelected] = useState(false)
  const handleSelect = () => {
    setSelected(!selected)
  }
	return (
		<>
      <SectionContainer>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionMenuContainer>
              <SectionMenuItem onClick={handleSelect}>
                <IconCheckBox><StyledIconCheck selected={selected} /></IconCheckBox>
                <SectionMenuItemText>{`지난 이벤트 보기`}</SectionMenuItemText>
              </SectionMenuItem>
              <SectionMenuItem>
              <StyledSelect>
                <option value={`모든그룹`} disabled selected>{`그룹별로 보기`}</option>
                <option value={`전체`} disabled>전체</option>
              </StyledSelect>

              </SectionMenuItem>
            </SectionMenuContainer>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
      <SectionContainer>
        {/* Upcoming events */}
        <SectionContentContainer hidden={selected}>
        </SectionContentContainer>
        {/* Past events */}
        <SectionContentContainer hidden={!selected}>
        <SectionContentRow>
          <EventContainer>
            <EventImage filename={"retreat"} width={18} />
            <EventDetailsContainer>
              <EventDetailsDate>{`3/31-4/1`}</EventDetailsDate>
              <EventDetailsName>중고등부 수련회</EventDetailsName>
              <EventDetailsTagContainer>
                <EventDetailsTag>교육부</EventDetailsTag>
              </EventDetailsTagContainer>
              <EventDetailsItemContainer>
                <EventDetailsItem>
                  장소: {`Alliance Redwood Conference`}
                  {/* <TagLink href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
                    <IconExternal style={{ marginLeft: '5px' }}/>
                  </TagLink> */}
                </EventDetailsItem>
              </EventDetailsItemContainer>
              <EventDetailsDesc>
                {`회비: $100, 문의: May Kim 전도사, 정병일 사역 부장`}
              </EventDetailsDesc>
              <EventDetailsCTAContainer>
              </EventDetailsCTAContainer>
            </EventDetailsContainer>
          </EventContainer>
          <EventContainer>
            <EventImage filename={"retreat"} width={18} />
            <EventDetailsContainer>
              <EventDetailsDate>{`4/14-4/16`}</EventDetailsDate>
              <EventDetailsName>카이로스 청년부 수련회</EventDetailsName>
              <EventDetailsTagContainer>
                <EventDetailsTag>청년부</EventDetailsTag>
              </EventDetailsTagContainer>
              <EventDetailsItemContainer>
                <EventDetailsItem>
                  장소: {`Camp Arroyo. 5535 Arroyo Rd Livermore`}
                  {/* <TagLink href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
                    <IconExternal style={{ marginLeft: '5px' }}/>
                  </TagLink> */}
                </EventDetailsItem>
              </EventDetailsItemContainer>
              <EventDetailsDesc>
                {`회비: Early bird $100. 03/26 이후 $125, 문의: 현동구 회장`}
              </EventDetailsDesc>
              <EventDetailsCTAContainer>
              </EventDetailsCTAContainer>
            </EventDetailsContainer>
          </EventContainer>
          <EventContainer>
            <EventImage filename={"dawn"} width={18} />
            <EventDetailsContainer>
              <EventDetailsDate>{`01.03 - 01.13.2023 화~금 (두 주간), 5:20AM`}</EventDetailsDate>
              <EventDetailsName>신년 특별 새벽집회</EventDetailsName>
              <EventDetailsTagContainer>
                <EventDetailsTag>전교인</EventDetailsTag>
              </EventDetailsTagContainer>
              <EventDetailsItemContainer>
                <EventDetailsItem>
                  장소: {`New Life Church (Dublin Campus) - Roots Room`}
                  <TagLink href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
                    <IconExternal style={{ marginLeft: '5px' }}/>
                  </TagLink>
                </EventDetailsItem>
              </EventDetailsItemContainer>
              <EventDetailsDesc>
                {`1월 3일부터 1월 13일까지 두 주간 특별 새벽집회를 (화-금) 오전 5:20부터 시작합니다.`}
              </EventDetailsDesc>
              <EventDetailsCTAContainer>
              </EventDetailsCTAContainer>
            </EventDetailsContainer>
          </EventContainer>
            <EventContainer>
              <EventImage filename={"retreat"} width={18} />
              <EventDetailsContainer>
                <EventDetailsDate>08.26-27.2022</EventDetailsDate>
                <EventDetailsName>목자 리더 수련회</EventDetailsName>
                <EventDetailsTagContainer>
                  {/* <EventDetailsTag>전교인</EventDetailsTag>  */}
                </EventDetailsTagContainer>
                <EventDetailsItemContainer>
                  <EventDetailsItem>
                    장소: 5535 Arroyo Rd. Livermore, CA 94550
                  </EventDetailsItem>
                </EventDetailsItemContainer>
                <EventDetailsDesc>
                {`대상: 목자/목녀, 부목자/목녀, 사역부장 부부, 제직 부부, 카이로스 청년부 리더`}
                </EventDetailsDesc>
                <EventDetailsCTAContainer>
                  <EventDetailsCTALink href="/august-2022">{`더 보기 >`}</EventDetailsCTALink>
                </EventDetailsCTAContainer>
              </EventDetailsContainer>
            </EventContainer>
            <EventContainer>
              <EventImage filename={"dawn"} width={18} />
              <EventDetailsContainer>
                <EventDetailsDate>08.27-28.2022</EventDetailsDate>
                <EventDetailsName>전교인 부흥집회</EventDetailsName>
                <EventDetailsTagContainer>
                  <EventDetailsTag>전교인</EventDetailsTag> 
                </EventDetailsTagContainer>
                <EventDetailsItemContainer>
                  <EventDetailsItem>
                    장소: San Ramon Presbyterian Church (SRPC)<br />12943 Alcosta Blvd, San Ramon, CA 94583
                  </EventDetailsItem>
                </EventDetailsItemContainer>
                {/* <EventDetailsDesc>
                {`대상: 전교인`}
                </EventDetailsDesc> */}
                <EventDetailsCTAContainer>
                  <EventDetailsCTALink href="/august-2022">{`더 보기 >`}</EventDetailsCTALink>
                </EventDetailsCTAContainer>
              </EventDetailsContainer>
            </EventContainer>
          </SectionContentRow>
					<SectionContentRow>
            <EventContainer>
              <EventImage filename={"vbs2022"} width={18} />
              <EventDetailsContainer>
                <EventDetailsDate>06.09 - 06.11.2022</EventDetailsDate>
                <EventDetailsName>{`VBS 2022 여름성경학교`}</EventDetailsName>
                <EventDetailsTagContainer>
                  <EventDetailsTag>교육부</EventDetailsTag> 
                </EventDetailsTagContainer>
                <EventDetailsItemContainer>
                  <EventDetailsItem>
                    장소: San Ramon Presbyterian Church (SRPC)<br />
                    12943 Alcosta Blvd, San Ramon CA 94583
                  </EventDetailsItem>
                  <EventDetailsItem>문의: elementary.joyfulchurch@gmail.com</EventDetailsItem>
                </EventDetailsItemContainer>
                <EventDetailsCTAContainer>
                  <EventDetailsCTALink href="/vbs-2022/">{`다시보기 >`}</EventDetailsCTALink>
                </EventDetailsCTAContainer>
              </EventDetailsContainer>
            </EventContainer>
            <EventContainer>
              <EventImage filename={"morning"} width={18} />
              <EventDetailsContainer>
                <EventDetailsDate>04.01 - 04.03.2022</EventDetailsDate>
                <EventDetailsName>{`카이로스 카리스마 연합수련회`}</EventDetailsName>
                <EventDetailsTagContainer>
                  <EventDetailsTag>대학부</EventDetailsTag> 
                  <EventDetailsTag>청년부</EventDetailsTag>
                </EventDetailsTagContainer>
                <EventDetailsItemContainer>
                  <EventDetailsItem>
                    장소: Redwood Christian Park<br />
                    15000 Two Bar Rd, Boulder Creek, CA 95006
                  </EventDetailsItem>
                  <EventDetailsItem>문의: doohee.han@joyfulchurch.us</EventDetailsItem>
                </EventDetailsItemContainer>
                <EventDetailsCTAContainer>
                  <EventDetailsCTALink href="/info/contact/">{`문의 >`}</EventDetailsCTALink>
                </EventDetailsCTAContainer>
              </EventDetailsContainer>
            </EventContainer>
            <EventContainer>
              <EventImage filename={"dawn"} width={18} />
              <EventDetailsContainer>
                <EventDetailsDate>{`2022.1.17 (월) ~ 21 (금) 5:25AM`}</EventDetailsDate>
                <EventDetailsName>특별 새벽집회</EventDetailsName>
                <EventDetailsTagContainer>
                  <EventDetailsTag>전교인</EventDetailsTag>
                </EventDetailsTagContainer>
                <EventDetailsItemContainer>
                  <EventDetailsItem>
                    장소: {`San Ramon Presbyterian Church (SRPC)`}
                    <TagLink href="https://goo.gl/maps/XbumoiCbALKVky8t9" target="_blank">
                      <IconExternal style={{ marginLeft: '5px' }}/>
                    </TagLink>
                  </EventDetailsItem>
                </EventDetailsItemContainer>
                <EventDetailsDesc>
                  {`1월 17일 월요일부터 금요일까지 한 주간 특별 새벽집회를 오전 5:25부터 시작합니다.`}
                </EventDetailsDesc>
                <EventDetailsCTAContainer>
                </EventDetailsCTAContainer>
              </EventDetailsContainer>
            </EventContainer>
          </SectionContentRow>
        </SectionContentContainer>
      </SectionContainer>
      <SectionContainer padding={`2`} />
		</>
	)
}

export default CalendarPageEvents
