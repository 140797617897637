import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import CalendarPageRecurring from './CalendarPageRecurring'
import CalendarPageEvents from './CalendarPageEvents'

// Page Banner
const PageBannerContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: auto;
    height: auto;
  }
`
const TitleHeader = styled.h1`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo Bold", "Apple SD Gothic Neo";
	align-items: center;
  font-size: 2.75em;
	font-weight: 700;
  text-align: center;
	line-height: 2.5rem;
	color: ${props => props.theme.colors.text};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 2.5em;
		line-height: 2.25rem;
	}
`
const TitleDescriptionContainer = styled.div` 
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 2rem;
`
const TitleDescription = styled.h5`
	word-break: keep-all;
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: ${props => props.fontSize ? props.fontSize + `em` : `1em`};
  font-weight: 300;
	line-height: ${props => props.fontSize ? props.fontSize + .2 + `rem` : `1.4rem`};
  margin-top: ${props => props.marginTop ? props.marginTop + `rem` : `initial`};
	margin-left: ${props => props.marginLeft ? props.marginLeft + `rem` : `initial`};
	margin-right: ${props => props.marginRight ? props.marginRight + `rem` : `initial`};
	margin-bottom: ${props => props.marginBottom ? props.marginBottom + `rem` : `initial`};
`
const BoldfaceText = styled.span`
  font-weight: ${props => props.fontWeight ? props.fontWeight : `700`};
`

// Banner Section
const InfoBannerSection = styled.section`
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background: ${`linear-gradient(315deg, rgba(188,224,244,0.55) 0%, #ECFFE9 100%)`};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		padding: 3.5rem 0;
	}
`
const InfoBannerSectionDetailsContainer = styled.div` 
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 0.5rem;
`
// Sections
const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
	margin-bottom: ${props => props.marginBottom ? props => props.marginBottom : 0}rem;
  padding: ${props => props.padding}rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 1 : 0}rem;
    padding: ${props => props.padding > 1 ? props => props.padding - 1 : props => props.padding}rem 0;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin-bottom: ${props => props.marginBottom ? props => props.marginBottom - 2 : 0}rem;
    padding: ${props => props.padding > 2 ? props => props.padding - 2 : props => props.padding}rem 0;
		background: ${props => props.background ? `rgba(174,183,191,0.1)` : 'initial'};
  }
`
const SectionContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
	max-width: 60rem;
  width: 100%;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
// In Page Navigation
const NavSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
	padding-bottom: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 2rem;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding-top: 2rem;
		padding-bottom: 2rem;
		background: ${props => props.background ? `rgba(174,183,191,0.1)` : 'initial'};
  }
`
const InPageTabNavContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
	}
`
const TabNavButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	color: ${props => props.currentTab ? props.theme.colors.orange : props.theme.colors.darkTextGrey};
	border-bottom: 2px solid ${props => props.currentTab ? props.theme.colors.orange : props.theme.colors.textGrey};
	font-size: 1.4rem;
	font-family: "Apple SD Gothic Neo";
	font-weight:  ${props => props.currentTab ? `600` : `400`};
	text-align: center;
	text-decoration: none;
	padding-bottom: 0.75rem;
	margin-left: ${props => props.marginLeft ? props.marginLeft + `rem` : `initial`};
	margin-right: ${props => props.marginRight ? props.marginRight + `rem` : `initial`};
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-left: ${props => props.marginLeft > 0 ? props.marginLeft - 0.25 + `rem` : `initial`};
		margin-right: ${props => props.marginRight > 0 ? props.marginRight - 0.25 + `rem` : `initial`};
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		font-size: 1.2rem;	
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		padding-bottom: 0.5rem;
	}
	/* For iPhone 5/SE */
  @media screen and (max-width: 350px) {
    max-width: 7rem;
  }
`

const CalendarPage = ({ hash }) => {
	const currentTab = hash || '#recurring' // defaults to recurring
	return (
		<>
			<InfoBannerSection padding={5}>
				<InfoBannerSectionDetailsContainer paddingBottom={2.5}>
					<TitleContainer>
						<TitleHeader>
							{`교회 일정`}
						</TitleHeader>
						<TitleDescriptionContainer>
							<TitleDescription marginLeft={1.5} marginRight={1.5}>
								{`전체일정과 모든 행사들을 한 눈에 볼수 있는 페이지 입니다.`}
							</TitleDescription>
						</TitleDescriptionContainer>
					</TitleContainer>
				</InfoBannerSectionDetailsContainer>
			</InfoBannerSection>
			<NavSectionContainer padding={4} background={`lightGrey`}>
				<SectionContentContainer>
					<InPageTabNavContainer>
						<TabNavButton 
							marginLeft={`0`}
							marginRight={`1`}
							to="/info/calendar#recurring"
							currentTab={currentTab == `#recurring`}
						>
							{`전체일정`}
						</TabNavButton>
						<TabNavButton 
							marginLeft={`1`}
							marginRight={`0`}
							to="/info/calendar#events"
							currentTab={currentTab == `#events`}
						>
							{`행사일정`}
						</TabNavButton>
					</InPageTabNavContainer>
				</SectionContentContainer>
			</NavSectionContainer>
			{currentTab == `#recurring` && <CalendarPageRecurring /> } 
			{currentTab == `#events` && <CalendarPageEvents /> } 
		</>
	)
}

export default CalendarPage
